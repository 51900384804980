import { SessionStore } from '../store'

import { SESSION } from '../actions/types'

const INITIAL_STATE: SessionStore = {
  user: {},
  settings: null,
}

export default (
  state = INITIAL_STATE,
  action: {
    type: SESSION
    [key: string]: any
  }
): SessionStore => {
  switch (action.type) {
    case SESSION.SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case SESSION.SETTINGS:
      return {
        ...state,
        settings: action.settings,
      }
    case SESSION.SIGN_OUT:
      return INITIAL_STATE
  }

  return state
}
