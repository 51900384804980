import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Auth from '@aws-amplify/auth'

class SignIn extends React.PureComponent<{
  classes: {
    root: string
  }
  goToHome()
  setUser(object)
}> {
  render() {
    return (
      <form onSubmit={this.signIn} className={this.props.classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h4">Kirjaudu sisään</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField variant="outlined" label="Sähköpostiosoite" name="username" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Salasana"
              type="password"
              name="password"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Kirjaudu
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  signIn = async e => {
    e.preventDefault()

    console.log('Signing in')

    try {
      await Auth.signIn(e.target.elements.username.value, e.target.password.value)

      this.props.goToHome()

      const user = await Auth.currentAuthenticatedUser()
      this.props.setUser(user)
    } catch (err) {
      console.log(`Cannot sign in`, err)
    }
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    margin: '0 auto',
    maxWidth: '20em',
  },
})

export default withStyles(styles)(SignIn)
