const amplifyConfProd = {
  auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_JeDaauaBQ',
    userPoolWebClientId: '6b0n7n9l252n3sdiqmogeek4im',
  },
  api: {
    endpoint: 'https://admin-api.poplastore.com/api',
  },
}

const amplifyConfSyte = {
  auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_EdiSyrFGX',
    userPoolWebClientId: '2f705gs0tq0ghk30cn2rf0rart',
  },
  api: {
    endpoint: 'https://admin-api.popupshop.sandbox.poplatek.com/api',
  },
}

const localApiEndpoint = 'http://poplastore.local/api'

export const getAmplifyConf = () => {
  const host = location.hostname
  let conf = {
    auth: {},
    api: {},
  }

  if (/poplastore.com$/i.test(host)) {
    conf = amplifyConfProd
    return conf
  } else if (/poplatek.com$/i.test(host)) {
    conf = amplifyConfSyte
    return conf
  } else {
    conf.auth = amplifyConfSyte.auth
    conf.api.endpoint = localApiEndpoint
    return conf
  }
}
