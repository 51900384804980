import { SESSION } from './types'

import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { push } from 'connected-react-router'

export const setUser = user => async dispatch => {
  dispatch({
    type: SESSION.SET_USER,
    user,
  })

  await dispatch(loadMerchantSettings())
}

export const signOut = () => async dispatch => {
  await Auth.signOut()

  dispatch(push('/login'))

  dispatch({
    type: SESSION.SIGN_OUT,
  })
}

export const loadMerchantSettings = () => async dispatch =>
  dispatch({
    type: SESSION.SETTINGS,
    settings: await API.get('admin-api', '/merchant/settings', {}),
  })

export const saveSettings = settings => async dispatch => {
  await API.post('admin-api', '/merchant/settings/save', {
    body: settings,
  })

  dispatch(loadMerchantSettings())
}
