import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'

import resources from './translations.json'

i18n.use(LngDetector).init({
  fallbackLng: 'en',
  resources,
})

export const languages = Object.keys(resources)

export default i18n
