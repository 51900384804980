import React from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

declare interface Settings {
  subdomain: string
  name: string
  address: string
  city: string
  postal_code: string
  country: string
  email: string
  receipt_recipients: string[]
  contact_name: string
  contact_phone: string
}

class Profile extends React.PureComponent<
  {
    signOut()
    saveSettings(settings: Settings)
    settings: Settings
  },
  {
    settings: Settings
  }
> {
  state = {
    settings: null,
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.settings && !state.settings) {
      return {
        settings: props.settings,
      }
    }
  }

  render = () => {
    if (!this.state.settings) {
      return <p>Ole hyvä ja odota ...</p>
    }

    return (
      <div>
        <Card>
          <CardContent>
            <Typography>
              Kirjautuneena <strong>{this.props.user && this.props.user.attributes.email}</strong>
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={this.props.signOut}>Kirjaudu ulos</Button>
          </CardActions>
        </Card>

        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Omat tiedot
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              multiline
              helperText="Kirjoita kukin sähköpostiosoite omalle rivilleen"
              label="Kuittien lähetysosoitteet"
              value={this.state.settings.receipt_recipients.join('\n')}
              onChange={e =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    receipt_recipients: e.target.value.split('\n'),
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              value={this.state.settings.terms}
              onChange={this.handleChange('terms')}
              variant="outlined"
              label="Tilausehdot"
              helperText="Ehdot, jotka käyttäjän tulee hyväksyä ennen maksua. Käytä ehtojen muotoiluun Markdownia."
              inputProps={{
                style: {
                  fontFamily: 'Menlo',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={e => this.props.saveSettings(this.state.settings)}
            >
              Tallenna
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  handleChange = fieldName => e =>
    this.setState({
      settings: {
        ...this.state.settings,
        [fieldName]: e.target.value,
      },
    })
}

export default Profile
