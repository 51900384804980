import { connect } from 'react-redux'

import CampaignEditor from '../components/CampaignEditor'

import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  subdomain: state.session.settings && state.session.settings.subdomain,
})

const mapDispatchToProps = {
  //
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignEditor)
