import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import NumberFormat from 'react-number-format'
import SwipeableViews from 'react-swipeable-views'

import API from '@aws-amplify/api'

import DatePicker from './DatePicker'

const Part = ({ ...other }) => <div style={{ padding: '0.5em' }} {...other} />

class CampaignEditor extends React.PureComponent<
  {
    classes: {
      root: string
      error: string
    }
    subdomain: string
  },
  {
    link: boolean
    loading: boolean
    error: string
    campaign: {
      id?: string
      amount: number
      title: string
      name: string
      message: string
      collectAddress: boolean
      collectMessage: boolean
      theme: any
    }
    currentSlide: number
  }
> {
  state = {
    link: false,
    loading: false,
    error: null,
    campaign: {
      amount: 0,
      title: '',
      name: '',
      message: '',
      collectAddress: false,
      collectMessage: false,
      theme: {},
    },
    currentSlide: 0,
  }

  slides = [0, 1, 2]

  render = () => {
    const { classes, subdomain } = this.props
    const { campaign } = this.state
    const { amount, title, name, message } = campaign

    const page1 = (
      <div>
        <Part>
          <TextField
            label="Tuotteen nimi"
            fullWidth
            variant="outlined"
            value={title}
            onChange={this.handleChange('title')}
          />
        </Part>
        <Part>
          <TextField
            label="Tuotteen hinta"
            fullWidth
            variant="outlined"
            value={amount / 100}
            onChange={e =>
              this.setState({
                campaign: {
                  ...this.state.campaign,
                  amount: parseFloat(e.target.value) * 100,
                },
              })
            }
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </Part>
        <Part>
          <TextField
            label="Viesti asiakkaalle"
            helperText="Voit käyttää muotoiluun Markdownia"
            multiline
            fullWidth
            rows="3"
            variant="outlined"
            value={message}
            onChange={this.handleChange('message')}
          />
        </Part>
        {!this.state.campaign.id && (
          <Part>
            <TextField
              variant="outlined"
              label="Kampanjan osoites"
              placeholder="kampanjan-osoite"
              fullWidth
              value={name}
              onChange={this.handleChange('name')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`https://${subdomain}.poplastore.com/`}</InputAdornment>
                ),
              }}
            />
          </Part>
        )}
      </div>
    )

    const page2 = (
      <div>
        <Part>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.campaign.collectAddress}
                onChange={this.handleChange('collectAddress')}
                value="1"
              />
            }
            label="Kerää toimitusosoite"
          />
        </Part>
        <Part>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.campaign.collectMessage}
                onChange={this.handleChange('collectMessage')}
                value="1"
              />
            }
            label="Salli lisätietojen antaminen"
          />
        </Part>
        <Part>
          <DatePicker
            label="Alkupäivä"
            name="startDate"
            value={this.state.campaign.starts}
            onChange={this.handleChange('starts')}
          />
          <br />
          <br />
          <DatePicker
            label="Loppupäivä"
            name="endDate"
            value={this.state.campaign.ends}
            onChange={this.handleChange('ends')}
          />
        </Part>
      </div>
    )

    const page3 = (
      <div style={{ textAlign: 'center', marginTop: '4em' }}>
        <Typography variant="h4">
          ✅<br />
          {this.state.campaign.id ? 'Tiedot päivitetty' : 'Kampanja luotu'}
        </Typography>
        <Part>
          <Typography>{`https://${subdomain}.poplastore.com/${
            this.state.campaign.name
          }`}</Typography>
        </Part>
        <Part>
          <Button
            onClick={() =>
              window.open(`https://${subdomain}.poplastore.com/${this.state.campaign.name}`)
            }
          >
            Avaa uuteen välilehteen
          </Button>
        </Part>
      </div>
    )

    const steps = ['Tuote', 'Lisäasetukset', 'Valmis']

    return (
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom>
          {this.state.campaign.id ? 'Muokkaa kampanjaa' : 'Uusi kampanja'}
        </Typography>
        <Typography gutterBottom>
          Kampanjan avulla voit myydä helposti verkossa. Kullakin kampanjalla on määriteltävissä
          oleva osoite joka voidaan jakaa esimerkiksi sosiaalisessa mediassa.
        </Typography>
        <Stepper activeStep={this.state.currentSlide}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {this.state.error && <Typography className={classes.error}>{this.state.error}</Typography>}

        <SwipeableViews disabled index={this.state.currentSlide}>
          {page1}
          {page2}
          {page3}
        </SwipeableViews>

        {this.state.currentSlide < this.slides.length - 1 && (
          <Part>
            {this.state.currentSlide >= 1 && (
              <Button
                disabled={this.state.loading}
                onClick={this.goSlide(this.state.currentSlide - 1)}
                children="Takaisin"
                style={{ float: 'left' }}
              />
            )}
            <Button
              variant="contained"
              color="secondary"
              disabled={this.state.loading}
              onClick={this.goSlide(this.state.currentSlide + 1)}
              children="Seuraava"
              style={{ float: 'right' }}
            />
          </Part>
        )}
      </div>
    )
  }

  goSlide = (slide: number) => () => {
    if (slide >= 0 && slide <= this.slides.length - 2) {
      this.setState({
        currentSlide: slide,
      })
    } else if (slide > this.slides.length - 2) {
      this.sendForm()
    }
  }

  handleChange = fieldName => e =>
    this.setState({
      link: false,
      campaign: {
        ...this.state.campaign,
        [fieldName]: e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value,
      },
    })

  sendForm = async () => {
    const { id, name, ...others } = this.state.campaign

    this.setState({
      loading: true,
    })

    try {
      const opts = {
        body: {
          name,
          ...others,
        },
      }

      if (id) {
        // Update campaign if ID already exists
        await API.put('admin-api', `/campaigns/${id}`, opts)
      } else {
        // Create new campaign if there's no ID yet
        await API.post('admin-api', `/campaigns`, opts)
      }

      this.setState({
        currentSlide: this.slides.length - 1,
        loading: false,
        error: null,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e.response ? e.response.data.error : 'Network error',
      })
    }
  }

  componentDidMount = async () => {
    const campaignId = this.props.match.params.id

    if (campaignId && campaignId !== 'create') {
      this.setState({
        campaign: {
          ...this.state.campaign,
          id: campaignId,
        },
      })

      const campaign = await API.get('admin-api', `/campaigns/${campaignId}`, {})

      this.setState({
        campaign,
      })
    }
  }
}

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      console.log('Update amount', values.value)
      onChange({
        target: {
          value: values.value,
        },
      })
    }}
    decimalScale={2}
    isNumericString
    fixedDecimalScale
    allowNegative={false}
    decimalSeparator=","
    thousandSeparator=" "
    suffix=" €"
  />
)

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '40em',
  },
  ctaButton: {
    marginTop: theme.spacing.unit * 4,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold' as 'bold',
  },
})

export default withStyles(styles)(CampaignEditor)
