import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'

import API from '@aws-amplify/api'
import { Link } from 'react-router-dom'

class CampaignList extends React.PureComponent {
  state = {
    campaigns: [],
  }

  render = () => (
    <div>
      <Typography variant="h4">Kampanjat</Typography>
      <List>
        {this.state.campaigns.map(campaign => (
          <ListItem button component={Link} to={`/campaign/${campaign.name}`}>
            <ListItemText primary={campaign.title} />
          </ListItem>
        ))}
      </List>
      <Link to="/campaign/create">
        <Fab color="primary" aria-label="Luo uusi" className={this.props.classes.fab}>
          <AddIcon />
        </Fab>
      </Link>
    </div>
  )

  refreshList = async () => {
    const campaigns = await API.get('admin-api', '/campaigns', {})

    this.setState({
      campaigns,
    })
  }

  componentDidMount = () => this.refreshList()
}

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: '3em',
    right: '3em',
  },
})

export default withStyles(styles)(CampaignList)
