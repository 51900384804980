import { connect } from 'react-redux'
import { saveSettings, signOut } from '../actions/SessionActions'

import Profile from '../components/Profile'

import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  user: state.session.user,
  settings: state.session.settings,
})

const mapDispatchToProps = dispatch => ({
  saveSettings: settings => dispatch(saveSettings(settings)),
  signOut: () => dispatch(signOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
