import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { connectRouter, routerMiddleware } from 'connected-react-router'

import history from './history'

// Reducers
import session from './reducers/SessionReducer'

export declare interface RootState {
  session: SessionStore
  router: any
}

export declare interface SessionStore {
  user: object
  settings: {
    subdomain?: string
    name?: string
    address?: string
    city?: string
    postal_code?: string
    country?: string
    email?: string
    contact_name?: string
    contact_phone?: string
  }
}

const rootReducer = combineReducers({
  session,
  router: connectRouter(history),
})

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
)
