import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#39B54A',
    },
    secondary: {
      main: '#E81584',
    },
    background: {
      default: '#1A222C',
      paper: '#1A222C',
    },
  },
  typography: {
    h4: {
      fontFamily: `'Merriweather', serif`,
    },
  },
})
