import { connect } from 'react-redux'

import { setUser, loadMerchantSettings, signOut } from '../actions/SessionActions'

import App from '../components/App'

import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  user: state.session.user,
  location: state.router.location,
})

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  signOut: () => dispatch(signOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
