import React from 'react'

import classNames from 'classnames'
import { Link, Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SvgIcon from '@material-ui/core/SvgIcon'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CssBaseline from '@material-ui/core/CssBaseline'
import DescriptionIcon from '@material-ui/icons/Description'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import NewReleasesIcon from '@material-ui/icons/NewReleases'

import PoplastoreSrc from '../images/logo.svg'
import FrontPage from './FrontPage'
import CreateLink from './CreateLink'
import CampaignList from './CampaignList'
import CampaignEditor from '../containers/CampaignEditor'
import Profile from '../containers/Profile'

const DRAWER_WIDTH = 240

const PoplaIconButton = (
  <SvgIcon viewBox="0 0 17 74">
    <path
      d="M5.435 72.0598C4.88569 71.5101 4.51169 70.8098 4.3603 70.0476C4.2089 69.2853 4.28691 68.4953 4.58445 67.7773C4.88199 67.0594 5.38571 66.4458 6.03193 66.0141C6.67815 65.5824 7.43785 65.352 8.215 65.352C8.99216 65.352 9.75186 65.5824 10.3981 66.0141C11.0443 66.4458 11.548 67.0594 11.8456 67.7773C12.1431 68.4953 12.2211 69.2853 12.0697 70.0476C11.9183 70.8098 11.5443 71.5101 10.995 72.0598C10.2566 72.7951 9.25702 73.2078 8.215 73.2078C7.17299 73.2078 6.1734 72.7951 5.435 72.0598V72.0598ZM4.425 54.3498C3.67678 53.5997 3.16767 52.6446 2.96198 51.6052C2.75629 50.5659 2.86325 49.4889 3.26934 48.5103C3.67543 47.5317 4.36244 46.6954 5.24359 46.107C6.12474 45.5187 7.16049 45.2047 8.22 45.2047C9.27952 45.2047 10.3153 45.5187 11.1964 46.107C12.0776 46.6954 12.7646 47.5317 13.1707 48.5103C13.5768 49.4889 13.6837 50.5659 13.478 51.6052C13.2723 52.6446 12.7632 53.5997 12.015 54.3498C11.0081 55.3556 9.64316 55.9205 8.22 55.9205C6.79685 55.9205 5.4319 55.3556 4.425 54.3498V54.3498ZM3.415 35.0098C2.46505 34.0604 1.81801 32.8505 1.55573 31.5333C1.29345 30.2161 1.42771 28.8506 1.94152 27.6097C2.45534 26.3688 3.32563 25.3081 4.44231 24.5619C5.559 23.8156 6.87192 23.4173 8.215 23.4173C9.55809 23.4173 10.871 23.8156 11.9877 24.5619C13.1044 25.3081 13.9747 26.3688 14.4885 27.6097C15.0023 28.8506 15.1366 30.2161 14.8743 31.5333C14.612 32.8505 13.965 34.0604 13.015 35.0098C11.7417 36.2824 10.0152 36.9973 8.215 36.9973C6.4148 36.9973 4.68827 36.2824 3.415 35.0098V35.0098ZM2.405 14.0298C1.25615 12.88 0.473993 11.4153 0.157415 9.82096C-0.159164 8.22663 0.00404614 6.57424 0.626413 5.07266C1.24878 3.57107 2.30236 2.28771 3.65398 1.38481C5.00559 0.48191 6.59455 0 8.22 0C9.84546 0 11.4344 0.48191 12.786 1.38481C14.1376 2.28771 15.1912 3.57107 15.8136 5.07266C16.436 6.57424 16.5992 8.22663 16.2826 9.82096C15.966 11.4153 15.1839 12.88 14.035 14.0298C12.4919 15.5705 10.4005 16.4359 8.22 16.4359C6.03947 16.4359 3.94806 15.5705 2.405 14.0298"
      fill="#39B54A"
    />
  </SvgIcon>
)

const PoplaLogo = <img src={PoplastoreSrc} alt="Poplastore" style={{ height: '2em' }} />

const MenuItemX = ({ to, icon, ...other }) => (
  <Link to={to}>
    <ListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText {...other} />
    </ListItem>
  </Link>
)

class AuthenticatedView extends React.PureComponent<
  {
    signOut()
    goTo(path: string)
    user: {
      attributes: {
        email: string
      }
    }
    classes: {
      root: string
      appBar: string
      appBarShift: string
      menuButton: string
      hide: string
      drawer: string
      drawerOpen: string
      drawerClose: string
      toolbar: string
      content: string
      grow: string
      logo: string
    }
  },
  {
    anchorEl: any
    mobileMoreAnchorEl: any
    drawerOpen: boolean
  }
> {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    drawerOpen: true,
  }

  toggleDrawer = () =>
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    })

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.handleMobileMenuClose()
  }

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state
    const { classes, user, signOut, goTo } = this.props

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.drawerOpen,
            [classes.drawerClose]: !this.state.drawerOpen,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.drawerOpen,
              [classes.drawerClose]: !this.state.drawerOpen,
            }),
          }}
          open={this.state.drawerOpen}
        >
          <List>
            <Link to="/">
              <ListItem button>
                <ListItemIcon>{this.state.drawerOpen ? PoplaLogo : PoplaIconButton}</ListItemIcon>
              </ListItem>
            </Link>
            <Divider />
            <MenuItemX to="/" primary="Etusivu" icon={<HomeIcon />} />
            <MenuItemX to="/invoice/new" primary="Laskut" icon={<DescriptionIcon />} />
            <MenuItemX to="/campaigns" primary="Kampanjat" icon={<NewReleasesIcon />} />
          </List>
          <div style={{ flex: 1 }} />
          <List>
            <MenuItemX
              to="/profile"
              primary={user.attributes.email}
              secondary="Omat asetukset"
              icon={<AccountCircleIcon />}
            />

            <ListItem button onClick={() => this.toggleDrawer()}>
              <ListItemIcon>
                {this.state.drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </ListItemIcon>
              <ListItemText primary="Pienennä valikko" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <Switch>
            <Route path="/" exact component={FrontPage} />
            <Route path="/invoice/new" exact component={CreateLink} />
            <Route path="/campaigns" exact component={CampaignList} />
            <Route path="/campaign/:id" exact component={CampaignEditor} />
            <Route path="/campaign/create" exact component={CampaignEditor} />
            <Route path="/profile" exact component={Profile} />
          </Switch>
        </main>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap' as 'nowrap',
    display: 'flex',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden' as 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flex: 1,
  },
  logo: {
    height: '1.5em',
  },
})

export default withStyles(styles)(AuthenticatedView)
