import React from 'react'

import uuid from 'uuid'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'

enum FormStep {
  BASIC_INFORMATION,
  EMAIL_CONFIRMATION,
}

class SignUp extends React.PureComponent<
  // Props
  {
    classes: {
      root: any
    }
  },
  // State
  {
    step: FormStep
    loading: boolean
    errors: {
      [key: string]: any
    }
    details: {
      username: string
      password: string
      name: string
      address: string
      country: string
      city: string
      postal_code: string
      email: string
      subdomain: string
    }
  }
> {
  state = {
    loading: false,
    step: FormStep.BASIC_INFORMATION,
    errors: {},
    details: {
      username: '',
      password: '',
      name: '',
      address: '',
      country: 'FI',
      city: '',
      postal_code: '',
      email: '',
      subdomain: '',
    },
  }

  fieldValidation = (name: string) => {
    if (!this.state.errors[name]) {
      return {}
    }

    return {
      error: true,
      helperText: this.state.errors[name],
    }
  }

  setError = (field: string, error: string) =>
    this.setState({
      loading: false,
      errors: {
        [field]: error,
      },
    })

  render = () =>
    ({
      [FormStep.BASIC_INFORMATION]: (
        <div className={this.props.classes.root}>
          <form onSubmit={this.signUp}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6">Perustiedot</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('company_name')}
                  variant="outlined"
                  label="Yrityksen nimi"
                  name="company_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('subdomain')}
                  variant="outlined"
                  placeholder="yrityksen-nimi"
                  fullWidth
                  helperText="Valitse yrityksellesi mieluinen osoite. Voit käyttää viivoja, kirjaimia a-z sekä numeroita 0-9. Pituus tulee olla 4-20 merkkiä. Pidätämme oikeuden muuttaa osoitetta mikäli nimi on asiaton tai kuuluu toiselle yritykselle." // eslint-disable-line max-len
                  name="subdomain"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">.poplastore.com</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Osoite</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('street_address')}
                  variant="outlined"
                  label="Katuosoite"
                  name="street_address"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...this.fieldValidation('postal_code')}
                  variant="outlined"
                  label="Postinumero"
                  name="postal_code"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  {...this.fieldValidation('city')}
                  variant="outlined"
                  label="Kaupunki"
                  name="city"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Yhteyshenkilö</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('contact_name')}
                  variant="outlined"
                  label="Yhteyshenkilön nimi"
                  name="contact_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('email')}
                  variant="outlined"
                  label="Sähköpostiosoite"
                  name="email"
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...this.fieldValidation('contact_phone')}
                  variant="outlined"
                  label="Puhelinnumero"
                  type="tel"
                  name="contact_phone"
                  fullWidth
                />
              </Grid>

              <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant="h6">Salasana</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...this.fieldValidation('password')}
                    variant="outlined"
                    label="Salasana"
                    name="password"
                    type="password"
                    helperText="Varmistathan että käytät vahvaa salasanaa"
                    {...this.fieldValidation('password')}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.state.loading}
                  children="Aloita myyminen"
                />
              </Grid>
            </Grid>
          </form>
        </div>
      ),
      [FormStep.EMAIL_CONFIRMATION]: (
        <div className={this.props.classes.root}>
          <form onSubmit={this.verifyCode}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6">Sähköpostivahvistus</Typography>
                <Typography>
                  Olet melkein valmis! Syötä vielä sähköpostiin saamasi varmistuskoodi alle.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth variant="outlined" label="Varmistuskoodi" name="code" />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  children="Vahvista"
                />
              </Grid>
            </Grid>
          </form>
        </div>
      ),
    }[this.state.step])

  signUp = async e => {
    e.preventDefault()

    const {
      company_name,
      subdomain,
      street_address,
      postal_code,
      city,
      contact_name,
      email,
      password,
      contact_phone,
    } = e.target.elements

    const username = uuid.v4()

    this.setState({
      loading: true,
    })

    const details = {
      username,
      password: password.value,
      name: company_name.value,
      address: street_address.value,
      country: 'FI',
      city: city.value,
      postal_code: postal_code.value,
      email: email.value,
      subdomain: subdomain.value,
      contact_name: contact_name.value,
      contact_phone: contact_phone.value,
    }

    console.log(details)

    // Validations

    if (!details.name) {
      return this.setError('company_name', 'Pakollinen tieto')
    }
    if (!details.subdomain.toLowerCase().match(/^[a-z0-9\-]{4,20}$/)) {
      return this.setError(
        'subdomain',
        'Osoitteen tulee olla 4-20 merkkiä pitkä ja voi sisältää aakkosia a-z, numeroita sekä viivan'
      )
    }
    if (!details.address) {
      return this.setError('street_address', 'Pakollinen tieto')
    }
    if (details.postal_code.length < 5) {
      return this.setError('postal_code', 'Pakollinen tieto')
    }
    if (!details.city) {
      return this.setError('city', 'Pakollinen tieto')
    }
    if (!details.country) {
      return this.setError('country', 'Pakollinen tieto')
    }
    if (!details.contact_name) {
      return this.setError('contact_name', 'Pakollinen tieto')
    }
    if (!details.email) {
      return this.setError('email', 'Pakollinen tieto')
    }
    if (!details.contact_phone) {
      return this.setError('contact_phone', 'Pakollinen tieto')
    }
    if (password.length < 6) {
      return this.setError('password', 'Salasana tulee olla vähintään 6 merkkiä pitkä')
    }

    try {
      await Auth.signUp({
        username,
        password: password.value,
        attributes: {
          email: email.value,
        },
      })

      this.setState({
        step: FormStep.EMAIL_CONFIRMATION,
        details,
        loading: false,
      })
    } catch (e) {
      console.log(e)

      this.setState({
        loading: false,
      })
    }
  }

  verifyCode = async e => {
    e.preventDefault()

    try {
      await Auth.confirmSignUp(this.state.details.username, e.target.elements.code.value)
      await Auth.signIn(this.state.details.username, this.state.details.password)

      await API.post('admin-api', '/merchant/create', {
        body: this.state.details,
      })

      location.reload()
    } catch (e) {
      alert('Vahvistus ei onnistunut')
      console.log(e)
    }
  }

  validate = () => {}
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    margin: '0 auto',
    maxWidth: '25em',
  },
})

export default withStyles(styles)(SignUp)
