// @format

import React from 'react'

import { hot } from 'react-hot-loader'
import { Route, Switch } from 'react-router-dom'

// Amplify
import Auth from '@aws-amplify/auth'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import SignIn from '../containers/SignIn'
import SignUp from './SignUp'

import AuthenticatedView from '../containers/AuthenticatedView'

class App extends React.PureComponent<{
  user: any
  classes: { root: string }
  setUser(user)
  signOut()
}> {
  render() {
    const { classes, user } = this.props

    return (
      <div className={classes.root}>
        <CssBaseline />

        {user && user.attributes && user.attributes.email_verified ? (
          <AuthenticatedView />
        ) : (
          <Switch>
            <Route path="/" exact component={SignUp} />
            <Route path="*" component={SignIn} />
          </Switch>
        )}
      </div>
    )
  }

  componentDidMount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()

      this.props.setUser(user)
    } catch (e) {
      console.log(e)
      this.props.signOut()
    }
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '1vh',
  },
})

export default hot(module)(withStyles(styles)(App))
