import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { setUser } from '../actions/SessionActions'

import SignIn from '../components/SignIn'

import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  //
})

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  goToHome: () => dispatch(push('/')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
