import { connect } from 'react-redux'
import { signOut } from '../actions/SessionActions'

import { push } from 'connected-react-router'

import AuthenticatedView from '../components/AuthenticatedView'

import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  user: state.session.user,
  router: state.router,
})

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOut()),
  goTo: (path: string) => dispatch(push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedView)
