import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import LogoSrc from '../images/logo.svg'

class FrontPage extends React.PureComponent<{
  classes: {
    root: string
  }
}> {
  render = () => (
    <div className={this.props.classes.root}>
      <img src={LogoSrc} alt="Poplastore" />
    </div>
  )
}

const styles = theme => ({
  root: {
    textAlign: 'center' as 'center',
  },
})

export default withStyles(styles)(FrontPage)
