import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'

import NumberFormat from 'react-number-format'

import API from '@aws-amplify/api'

class CreateLink extends React.PureComponent<
  {
    classes: {
      root: string
      error: string
    }
  },
  {
    link: boolean
    loading: boolean
    error: string
    form: {
      amount: number
      referenceNumber: string
      message: string
      collectAddress: boolean
    }
  }
> {
  private linkDebounce

  state = {
    link: false,
    loading: false,
    error: null,
    form: {
      amount: 0,
      referenceNumber: '',
      message: '',
      collectAddress: false,
    },
  }

  render = () => {
    const { classes } = this.props
    const { form, link } = this.state
    const { amount, referenceNumber, message } = form

    const amountInput = (
      <TextField
        label="Summa"
        fullWidth
        variant="outlined"
        value={amount / 100}
        onChange={e =>
          this.setState({
            form: {
              ...this.state.form,
              amount: parseFloat(e.target.value) * 100,
            },
          })
        }
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    )

    const referenceInput = (
      <TextField
        label="Kauppiaan viite"
        fullWidth
        variant="outlined"
        value={referenceNumber}
        onChange={this.handleChange('referenceNumber')}
      />
    )

    const messageInput = (
      <TextField
        label="Viesti asiakkaalle"
        multiline
        fullWidth
        rows="3"
        variant="outlined"
        value={message}
        onChange={this.handleChange('message')}
      />
    )

    const linkOutput = (
      <TextField
        label="Maksulinkki asiakkaalle"
        value={link || ''}
        variant="outlined"
        disabled={!link}
        fullWidth
        InputProps={{ readOnly: true }}
        inputProps={{
          style: { userSelect: 'all', WebkitUserSelect: 'all', fontFamily: 'Menlo, monospace' },
        }}
      />
    )

    const collectAddressCheckbox = (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.form.collectAddress}
            onChange={this.handleChange('collectAddress')}
            value="1"
          />
        }
        label="Kerää toimitusosoite"
      />
    )

    return (
      <Grid container spacing={16} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Luo maksulinkki
          </Typography>
          <Typography variant="body1">
            Luo kertakäyttöinen laskulinkki. Laskun viitenumeron on oltava uniikki.
          </Typography>
          {this.state.error && (
            <Typography className={classes.error}>{this.state.error}</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {amountInput}
        </Grid>
        <Grid item xs={12} md={6}>
          {referenceInput}
        </Grid>
        <Grid item xs={12}>
          {messageInput}
        </Grid>
        <Grid item xs={12}>
          {collectAddressCheckbox}
        </Grid>
        <Divider />
        <Grid item xs={12}>
          {linkOutput}
        </Grid>
      </Grid>
    )
  }

  handleChange = fieldName => e => {
    this.setState({
      link: false,
      form: {
        ...this.state.form,
        [fieldName]: e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value,
      },
    })

    if (this.linkDebounce) {
      clearTimeout(this.linkDebounce)
    }

    this.linkDebounce = setTimeout(async () => {
      this.setState({
        loading: true,
      })

      API.post('admin-api', '/campaigns/jwt', {
        body: this.state.form,
      })
        .then(response => {
          this.setState({
            loading: false,
            error: null,
            link: response.purchaseUrl,
          })
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err.response ? err.response.data.error : 'Network error',
          })
        })
    }, 1000)
  }
}

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      console.log('Update amount', values.value)
      onChange({
        target: {
          value: values.value,
        },
      })
    }}
    decimalScale={2}
    isNumericString
    fixedDecimalScale
    allowNegative={false}
    decimalSeparator=","
    thousandSeparator=" "
    suffix=" €"
  />
)

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '30em',
  },
  ctaButton: {
    marginTop: theme.spacing.unit * 4,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold' as 'bold',
  },
})

export default withStyles(styles)(CreateLink)
