// Define regenerator-runtime globally
import 'regenerator-runtime/runtime'

import React from 'react'
import { render } from 'react-dom'

// Redux & Routing
import store from './store'
import history from './history'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

// Internatization
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

// Theming
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

import { getAmplifyConf } from './utils/APIUtils'
import App from './containers/App'

const amplifyConf = getAmplifyConf()

Amplify.configure({
  Auth: {
    ...amplifyConf['auth'],
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: 'admin-api',
        // @ts-ignore
        endpoint: amplifyConf['api'].endpoint,
        custom_header: async () => {
          // @ts-ignore
          return { Authorization: (await Auth.currentSession()).getIdToken().jwtToken }
        },
      },
    ],
  },
})

document.addEventListener('DOMContentLoaded', () => {
  render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>,
    document.getElementById('root')
  )
})
